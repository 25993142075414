#fullpage {
	transform: translate3d(0px, 0px, 0px) !important;
}

.fp-overflow {
	height: 100%;
	/* overflow-y: visible !important; */
}

.logo {
	max-width: 30%
}

.helloworld {
	max-width: 90%;
}

.App {
	background-color: white;
}

@keyframes fade {
	0% {
		opacity: 1
	}

	100% {
		opacity: 0
	}
}

a {
	color: blue;
}

.mc {
	text-align: center;
}

.mr {
	text-align: right;
}

.ml {
	text-align: left;
}

@media (max-width: 900px) {

	.mc,
	.mr,
	ml {
		text-align: left;
	}

	.h4m {
		text-align: center
	}

	.carousel-indicators {
		filter: invert(1) !important;
	}
}

@media (max-width: 992px) {.logo {max-width: 50%}}


.cimg {
	max-height: 400px;
	max-width: 100%;
}

.cli {
	border-radius: 7px;
}

.carousel-indicators {
	margin-bottom: -15px !important;
}
.carousel-caption{
	top:auto;
	bottom:0;
	font-style: italic;
}

.carousel{
	/* max-height:350px; */
}

.box{
	padding-top:15px;
	padding-bottom:29px;
	/* border: 1px solid green; */
}


.image-strip{
	width:100%;
	white-space: nowrap;
    position: relative;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
	display: inline-block;
	padding: 20px;
	/* border-top: 1px solid black; */
	border-bottom: 1px solid black;
}

.image-strip-img{
	max-height: 250px;
	margin-right:30px;
}

::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 15px;
  }
  ::-webkit-scrollbar-thumb {

	background-color: rgba(0, 0, 0, 1);
	-webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
  }

  code{
	border-radius: 3px;
	padding:3px;
	padding-left:10px;
	padding-right:10px;
	background-color: rgb(0,0,0,0.07) !important;
	color:black !important;
	
  }

  .topbar{
	background-color: black;
	margin-left:0px ;
	margin-right:0px ;
	color:white;
	/* height:100px; */
	font-family: Arial, Helvetica, sans-serif;
	font-size:75px;
	display: inline-block;
  }

  .headerlinkbar{
	text-align: right;
	font-size: 14px;
	margin-right:2%;
  }
  .headerlink{
	color:black;
	text-decoration: none;
	font-weight: 900;
  }
  .headerlinkdiv{
	padding-left:2px;
	padding-right:2px;
	color:rgba(0, 0, 0, 0.1);
	font-size: 45px;
	vertical-align: middle;
  }