@font-face {
	font-family: "Computer Modern";
	src: url('./fonts/cmunrm.ttf');
}

/* @font-face {
	font-family: "Computer Modern";
	src: url('./fonts/cmunss.ttf');
} */

/* @font-face {
	font-family: "Computer Modern";
	src: url('./fonts/cmunsx.ttf');
	font-weight: bold;
} */

/* @font-face {
	font-family: "Computer Modern";
	src: url('./fonts/cmunsi.ttf');
	font-style: italic, oblique;
} */

/* @font-face {
	font-family: "Computer Modern";
	src: url('./fonts/cmunbxo.ttf');
	font-weight: bold;
	font-style: italic, oblique;
} */

body {
	margin: 0;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
	/* font-family: "Computer Modern" !important; */
	font-family: Helvetica !important;
	text-align: center;
	font-size: 14pt !important;
	/* user-select: none; */
}


code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}